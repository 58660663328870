<template>
  <div class="query_wrap">
    <h1 class="h_none">商标查询</h1>
    <h2 class="h_none">蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <!-- <headers></headers> -->
    <div class="report_main">
      <div class="top_title">
        <p>{{$t(`analysisReport.top_title[0]`)}}</p>
        <p>{{$t(`analysisReport.top_title[1]`)}}</p>
      </div>

      <div class="query_main">
        <div class="query_main_part1">
          <div class="part1_title">
            <p>{{$t(`analysisReport.part1_title[0]`)}}</p>
            <p>{{$t(`analysisReport.part1_title[1]`)}}</p>
          </div>

<!--          查询的条件-->
          <div class="condition">
            <p v-if="type == 1">{{tableData.name}}</p>
            <img style="max-width: 100%" v-else :src="this.tableData.name" alt="">
          </div>

<!--          查询结果-->
          <div class="result">
            <el-card shadow="always">
              <div class="result_title">
                <p>{{$t(`analysisReport.result_title[0]`)}}<span class="result_title1">{{$t(`analysisReport.result_title[1]`)}}</span></p>
              </div>

              <div class="result_main">
                <ul style="display: flex" v-if="rateHigh.length > 0">
                  <el-popover
                    v-for="(item,index) in rateHigh" :key="index"
                    placement="bottom"
                    width="200"
                    :title="`${item.nice}  ${item.name}`"
                    trigger="hover"
                    :content="item.title">
                    <li slot="reference" >{{item.nice}}</li>
                  </el-popover>
                </ul>
              </div>
            </el-card>

            <el-card shadow="always">
              <div class="result_title">
                <p>{{$t(`analysisReport.result_title[2]`)}}<span class="result_title2">{{$t(`analysisReport.result_title[3]`)}}</span></p>
              </div>

              <div class="result_main">
                <ul style="display: flex" v-if="rateMiddling.length > 0">
                  <el-popover
                    v-for="(item,index) in rateMiddling" :key="index"
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    :title="`${item.nice}  ${item.name}`"
                    :content="item.title">
                    <li slot="reference" >{{item.nice}}</li>
                  </el-popover>
                </ul>
              </div>
            </el-card>

            <el-card shadow="always">
              <div class="result_title">
                <p>{{$t(`analysisReport.result_title[4]`)}}<span class="result_title3">{{$t(`analysisReport.result_title[5]`)}}</span></p>
              </div>

              <div class="result_main">
                <ul style="display: flex" v-if="rateLow.length > 0">
                  <el-popover
                    v-for="(item,index) in rateLow" :key="index"
                    placement="bottom"
                    width="200"
                    trigger="hover"
                    :title="`${item.nice}  ${item.name}`"
                    :content="isCN ? item.title : ''">
                    <li slot="reference" >{{item.nice}}</li>
                  </el-popover>
                </ul>
              </div>
            </el-card>
          </div>

<!--          可注册建议-->
          <div class="proposal">
            <div class="proposal_title">
              {{$t(`analysisReport.proposal_title[0]`)}}
            </div>
            <template>
              <el-table
                class="table"
                :header-row-class-name="headerStyles"
                :stripe="true"
                :data="tableData.data"
                height="300px"
                style="width: 100%">
                <el-table-column
                  prop="nice"
                  :label="$t(`analysisReport.proposal_title[1]`)"
                  align="center"
                  width="80">
                </el-table-column>
                <el-table-column
                  prop="name"
                  align="center"
                  width="120"
                  :label="$t(`analysisReport.proposal_title[2]`)">
                </el-table-column>
                <el-table-column
                  prop="title"
                  :label="$t(`analysisReport.proposal_title[3]`)">
                </el-table-column>
                <el-table-column
                  prop="successRate"
                  align="center"
                  width="100"
                  :label="$t(`analysisReport.proposal_title[4]`)">
                </el-table-column>
              </el-table>
            </template>
          </div>

        </div>

      </div>
    </div>
    <footers></footers>
  </div>

</template>

<script>
  import { customizedOrder,priceList } from "@/request/trademarkPayment";
  import { mapState, mapMutations, mapGetters } from 'vuex'
  import login from "@/components/common/login"
  import footers from '@/components/common/xfooter'
  export default {
    name: "analysisReport",
    data () {
      return {
        dataType: this.$t(`analysisReport.dataType`),
        tableData:{},
        type:'',
        //注册率高
        rateHigh:[],
        //注册率中
        rateMiddling:[],
        //注册率低
        rateLow:[],
        table: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }]
      }
    },
    components: {
      footers
    },
    mounted () {
      this.tableData = JSON.parse(localStorage.getItem('data'));
      this.type = this.tableData.type
      if(this.tableData.data.length > 0){
        this.tableData.data.map((item,index) => {
          return Object.assign(item,this.dataType[index])
        })

        this.tableData.data.map((item,index) => {
          if(item.successRate >= 80){
            //注册率高
            this.rateHigh.push(item)
          }else if(20 <= item.successRate && item.successRate <80){
            this.rateMiddling.push(item)
          }else {
            //注册率低
            this.rateLow.push(item)
          }

          return item
        })
      }
    },
    methods:{
      headerStyles({row, rowIndex}){
        if (rowIndex === 0) {
          return 'headerStyles';
        } else if (rowIndex === 1) {
        }
        return '';
      }
    },
    computed: {
    },
    watch: {
    }
  }
</script>
<style lang="less" scoped>
  .query_wrap{
    background: #ffffff;
    .report_main{
      width: 1206px;
      margin: 0 auto;
      padding: 74px 0 145px;
      box-sizing: border-box;
      .top_title{
        /*border: 1px solid #EBEEF5;*/
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        box-sizing: border-box;
        height: 144px;
        margin-top: 20px;
        width: 100%;
        background-color: #ffffff;
        padding: 45px 0 0 38px;
        & p:nth-of-type(1){
          font-size: 19px;
          font-weight: bold;
          color: #333333;
        }
        & p:nth-of-type(2){
          margin-top: 23px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
      .query_main{
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        /*border: 1px solid #EBEEF5;*/
        width: 100%;
        margin-top: 10px;
        background-color: #ffffff;
        padding: 45px 36px 0 37px;
        box-sizing: border-box;
        .query_main_part1{
          .part1_title{
            margin-bottom: 22px;
            p:nth-of-type(1){
              font-size: 18px;
              color: #333333;
            }
            p:nth-of-type(2){
              margin-top: 23px;
              font-size: 14px;
              color: #333333;
            }
          }

          .condition{
            width: 360px;
            min-height: 175px;
            border: 1px solid #EBEEF5;
            background-color: #FFF;
            margin-bottom: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
            font-size: 22px;

            p{
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }

          .result{
            display: flex;
            justify-content: space-between;
            /deep/.el-card{
              width: 355px;
              min-height: 290px;
              margin-right: 28px;
              /deep/.el-card__body{
                box-sizing: border-box;
                padding: 34px 16px;
              }
            }
            ul{
              flex-wrap: wrap;
              margin-top: 20px;
              li{
                /*width: 35px;*/
                /*height: 35px;*/
                border-radius: 50%;
                cursor: pointer;
                padding: 13px 13px;
                /*margin-right: 16px;*/
                /*margin-bottom: 16px;*/
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover{
                  background-color: #f3f5fc;
                }
              }
            }
            .result_title{
              text-align: center;
              font-size: 15px;
              span{
                margin-left: 5px;
              }
              padding-bottom: 23px;
              box-sizing: border-box;
              border-bottom: 1px solid #eeeff0;
              .result_title1{
                color: #2dc7ad;
              }
              .result_title2{
                color: #e9ab5e;
              }
              .result_title3{
                color: #da4856;
              }
            }

          }

          .proposal{
            margin-top: 50px;
            padding-bottom: 60px;
            .proposal_title{
              font-size: 18px;
              color: #333333;
              margin-bottom: 32px;
            }
          }
        }
      }
    }
  }

</style>
<style>
  .headerStyles th{
    background-color: #2e54d9!important;
    color: #ffffff;
    text-align: center;
  }
</style>
